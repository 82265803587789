import React from 'react'

import { ObjViewer } from 'react-obj-viewer'
import { withPrefix } from 'gatsby'


const getSrc = relativeSrc => withPrefix(relativeSrc)  

const ModelViewer = () => (
  <div style={{ width: '500px', height: '600px' }}>
    <ObjViewer
      model={getSrc('/frt55.obj')}
      width={500}
      height={600}
      axis={false}
      boundingBox={false}
    />
  </div>
)

export default ModelViewer