import React from 'react'
import Layout from '../layouts/GenericLayout'
import pic11 from '../assets/images/pic11.jpg'
import PageSection from '../components/PageSection';


import { graphql } from 'gatsby';
import { I18n } from 'react-i18next';
import { withI18next } from 'gatsby-plugin-i18next';
import { getContentTranslation } from '../helpers';
import LightboxExample from '../components/Image/LightBox';
import ModelViewer from '../components/ModelViewer';


const Generic = () => <I18n>
    {(t, {i18n}) => {
    return <Layout>
            <PageSection>
                <header className="major">
                    <h1>Model sample</h1>
                </header>
                <ModelViewer/>
               
            </PageSection>
    </Layout>
}
}
</I18n>

export default withI18next()(Generic)

export const query = graphql`
query($lng: String!) {
  locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
    ...TranslationFragment
  }
}
`